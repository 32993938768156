<template>
	<div class="content box">
		<DateRange
            :start.sync="start_date"
            :end.sync="end_date"
            @submit="loadHistorique"
        />

		<CustomTable
			id_table="stock_local_historique"
			ref="table"
			:items="historique"
			:busy.sync="table_busy"
			primaryKey="uid"
	        :hrefsRoutes="config_table_hrefs"
			:externSlotColumns="extern_slot_columns"
		>
			<template v-slot:custom-slot-cell(type)="{ data }">
	        	<div class="d-flex align-items-center">
					<font-awesome-icon v-if="data.type == 'out'" class="typeColIndentifer red" :icon="['fal', 'long-arrow-right']" />
					<font-awesome-icon v-else-if="data.type == 'in'" class="typeColIndentifer green" :icon="['fal', 'long-arrow-left']" />
					<font-awesome-icon v-else-if="data.type == 'used'" class="typeColIndentifer" :icon="['fal', 'syringe']" />
					<font-awesome-icon v-else-if="data.type == 'destroy'" class="typeColIndentifer" :icon="['far', 'trash-alt']" />
	        	</div>
			</template>
		</CustomTable>

		<ModalCourrierFluxPaillettes ref="modal_courrier_flux_paillettes"/>

		<ModalEditFluxPailettes ref="modal_edit_flux_paillettes" @submit="init_component"/>
	</div>
</template>

<script type="text/javascript">
import Navigation from '@/mixins/Navigation.js'
import StockSemence from '@/mixins/StockSemence'

export default {
	name: 'HistoriqueStock',
	mixins: [Navigation, StockSemence],
	data () {
		return {
			start_date: new Date(new Date().setMonth(new Date().getMonth() - 3)),
			end_date: new Date(),
			historique: [],
			table_busy: false,
			extern_slot_columns: ['type'],
			config_table_hrefs: {
				'horse.horse_nom': {
					routeUniqueName: 'StallionFiche',
					routeName: 'horseFiche',
					params: {
						horse_id: 'horse.horse_id'
					}
				},
				'from.tiers_rs': {
					routeUniqueName: 'entityTableauBord',
					routeName: 'tiersTableauBord',
					params: {
						tiers_id: 'from.tiers_id'
					}
				},
				'to.tiers_rs': {
					routeUniqueName: 'tiersTableauBord',
					routeName: 'tiersTableauBord',
					params: {
						tiers_id: 'to.tiers_id'
					}
				},
				'acte.label': {
					routeUniqueName: 'MareFiche',
					routeName: 'horseFiche',
					params: {
						horse_id: 'acte.horse.horse_id'
					}
				},
			},
			events_tab: {
				'TableAction::goToCourrierStock': (params) => {
					this.openModalCourrierFluxPaillettes(params)
				},
				'TableAction::goToEditStockFlux': (params) => {
					this.openModalEditCommentaireStock(params)
				}
			}
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		async init_component() {
			this.loadHistorique()
		},

		async loadHistorique() {
			this.table_busy = true
			this.historique = await this.getFluxStockLocal(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
			this.table_busy = false
		},

		openModalCourrierFluxPaillettes(params) {
			this.$refs.modal_courrier_flux_paillettes.openModal(params)
		},

		openModalEditCommentaireStock(params) {
			this.$refs.modal_edit_flux_paillettes.openModal(params)
		}
	},

	components: {
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
		ModalCourrierFluxPaillettes: () => import('@/components/Modals/ModalCourrierFluxPaillettes'),
		ModalEditFluxPailettes: () => import('@/components/Modals/ModalEditFluxPailettes')
	}
}

</script>